import React from "react";
import Carousel from "react-slick";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
// import Chat from "@material-ui/icons/Chat";
// import VerifiedUser from "@material-ui/icons/VerifiedUser";
// import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import carouselStyle from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.jsx";
import image1 from "assets/img/Dr-Mubarak-Hamad-Al-Ameri-custom.jpg";
// testimonial1.png";
import quotebg from "assets/img/qt-bg.png";
import { Card } from "@material-ui/core";

class TestimonialSection extends React.Component {
  render() {
    const { classes } = this.props;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false
      };
    return (
      <div className={classes.section}>
        <div style={{marginTop:"-6em"}}>
          <GridContainer  style={{margin: "0",paddingTop:"6em"}}>
            <GridItem xs={12} sm={6} style={{backgroundSize:"cover",backgroundImage:"url(" + quotebg + ")"}}>
                <h3 className={classes.title} style={{color:"#6C648B",padding:"2em"}}>
                    <i>
                    We Are Grateful And Thankful To Customers Who Have
                    Given Us Their Trust And Honor Of Their Services For A 
                    Long Time And We Look Forward To A Better Tomorrow
                    For All.
                    </i>
                </h3>
            </GridItem>
            <GridItem xs={12} sm={6}>
                <div style={{padding:"1em"}}>
                <Card carousel style={{}}>
                    <Carousel {...settings}>
                    <div>
                        <img
                        src={image1}
                        alt="First slide"
                        className="slick-image"
                        />
                    </div>
                    </Carousel>
                </Card>
                <h4 className={classes.title} style={{color:"black", textAlign:"right"}}>
                    <i>H.E. Dr. Mubarak Hamad Al Ameri </i>
                    {/* <i>Dr. Mubarak Hamad Al Ameri.</i> */}
                </h4>
                </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(carouselStyle, productStyle)(TestimonialSection);
